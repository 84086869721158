import React from 'react';
import './TheMoreInfo.css'


interface Props {

}

const TheMoreInfo: React.FC<Props> = () => {
    return (
        /*
        <div className='more-info'>
            <div className='info-container'>
                <h2>
                    DALŠÍ FUNKCE,
                    <br />
                    KTERÉ PŘI CESTOVÁNÍ IDOLU
                    <br />
                    VYUŽIJETE
                </h2>
                <p>
                    <span>
                        Vyhledejte nerychlejší spojení
                    </span>
                    <br />
                    Aplikace zobrazí, jaké trasy můžete v rámci IDOL využít.
                </p>
                <p>
                    <span>
                        Zakupte a aktivujte jízdenku
                    </span>
                    <br />
                    Nakupujte jízdenky s možností aktivace až v případě potřeby.
                    <br />
                    Zakoupit si můžete až 10 jízdenek s možností jich aktivovat více najednou (ocení především rodiny s dětmi a školy). Samotný proces nákupu jízdenky je možné ještě urychlit a zjednodušit uložením platební karty do aplikace (bezpečně na platební bráně, aplikace se k vašemu číslu karty nedostane). Nákup jízdenky je pak opravdu otázkou jen několika málo kliknutí. Ke všem zakoupeným jízdenkám je navíc možné si přímo v aplikaci stáhnout daňový doklad ve formátu PDF.
                </p>
                <p>
                    <span>
                        Spravujte si svůj účet
                    </span>
                    <br />
                    Zaregistrujte se a vytvořte si tak uživatelský účet.
                </p>
                <p>
                    <span>
                        Vyrazte na cestu
                    </span>
                    <br />
                    Jízdenka má prodlevu 1 minuty od aktivace, než začne skutečně platit. Po uplynutí této ochranné lhůty je možné jízdenku použít. Při přepravní kontrole revizorem, řidičem nebo průvodčím se vždy prokazujte QR kódem nebo grafickým obrazcem v aplikaci.
                </p>
                <p>
                    <span>Přeposlání jízdenky</span>
                    <br />
                    Idolku lze používat i bez bankovních služeb, zakoupenou, ještě neaktivovanou jízdenku lze přeposlat jinému uživateli.
                </p>
                <p>
                    <span>
                        Využívejte lokalizaci vaší aktuální polohy
                    </span>
                    <br />
                    Pro snadné nalezení nejbližší zastávky ve vašem okolí.
                </p>
            </div>
        </div>*/
        <div id="description">
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-12 text-left">
                        <h2 className="montserrat-bold headline text-center pb-5">DALŠÍ FUNKCE,
                            <br/>KTERÉ PŘI CESTOVÁNÍ IDOLU
                            <br/>VYUŽIJETE</h2>

                        <p><span className="montserrat-light font-size-18 color-cred font-weight-bold">Vyhledejte nejrychlejší spojení</span>
                            <br/>Aplikace zobrazí, jaké trasy můžete v rámci IDOL využít.</p>


                        <p><span className="montserrat-light font-size-18 color-cred font-weight-bold">Zakupte a aktivujte jízdenku</span>
                            <br/>Nakupujte jízdenky s možností aktivace až v případě potřeby.
                            <br/>Zakoupit si můžete až 10 jízdenek s možností jich aktivovat více najednou (ocení především rodiny s dětmi a školy). Samotný proces nákupu jízdenky je možné ještě urychlit a zjednodušit uložením platební karty do aplikace (bezpečně na platební bráně, aplikace se k vašemu číslu karty nedostane). Nákup jízdenky je pak opravdu otázkou jen několika málo kliknutí. Ke všem zakoupeným jízdenkám je navíc možné si přímo v aplikaci stáhnout daňový doklad ve formátu PDF.</p>

                        <p><span className="montserrat-light font-size-18 color-cred font-weight-bold">Spravujte si svůj účet</span>
                            <br/>Zaregistrujte se a vytvořte si tak uživatelský účet.</p>

                        <p><span className="montserrat-light font-size-18 color-cred font-weight-bold">Vyrazte na cestu</span>
                            <br/>Jízdenka má prodlevu 1 minuty od aktivace, než začne skutečně platit. Po uplynutí této ochranné lhůty je možné jízdenku použít. Při přepravní kontrole revizorem, řidičem nebo průvodčím se vždy prokazujte QR kódem nebo grafickým obrazcem v&nbsp;aplikaci.</p>

                        <p><span className="montserrat-light font-size-18 color-cred font-weight-bold">Přeposlání jízdenky</span>
                            <br/>Idolku lze používat i bez bankovních služeb, zakoupenou, ještě neaktivovanou jízdenku lze přeposlat jinému uživateli.</p>

                        <p><span className="montserrat-light font-size-18 color-cred font-weight-bold">Využívejte lokalizaci vaší aktuální polohy</span>
                            <br/>Pro snadné nalezení nejbližší zastávky ve vašem okolí.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TheMoreInfo;