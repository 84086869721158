import './TheUpdates.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React, {useEffect, useState} from 'react'

interface DataItem {
    id: 1,
    attributes: {
        version: string,
        date: string,
        createdAt: string,
        updatedAt: string,
        publishedAt: string,
        changes: Array<string>,
        system: {
            data: {
                id: number,
                attributes: {
                    android: boolean,
                    ios: boolean,
                    createdAt: string,
                    updatedAt: string,
                    publishedAt: string
                }
            }
        }
    }
}

const TheUpdates: React.FC = () => {

    const [newsData, setNewsData] = useState<DataItem[]>([]);
    const get_link = `${process.env.REACT_APP_STRAPI_URL}news?populate=*`;

    useEffect(() => {
        fetch(get_link)
        .then(response => response.json())
        .then(jsonData => {
            const dataArray: DataItem[] = jsonData.data;
            const reversedArr = dataArray.reverse();
            setNewsData(reversedArr);
            console.log(reversedArr);
        })
        .catch(error => {
            console.log(error);
        });
    }, [get_link]);
    useEffect(() => {
        const elements = document.getElementsByClassName('version-tag');
        Array.from(elements).forEach((element: Element) => {
        if (element.textContent?.trim() === '') {
            (element as HTMLElement).style.display = 'none';
        } 
        });
    })
    return(
        <div className='container'>
            {newsData.map((item) => (
                <div key={item.id} className='content-field'>
                    <div className='inline'>
                        <h2 className='title'>{item.attributes.version}</h2>
                        <p className='version-tag date-tag'>{item.attributes.date}</p>
                        <p className='version-tag tag-android'>{item.attributes.system.data.attributes.android ? "Android" : ""}</p>
                        <p className='version-tag tag-ios'>{item.attributes.system.data.attributes.ios ? "iOS" : ""}</p>
                    </div>
                    <div className='changes'>
                        <ul>
                            {item.attributes.changes.map((change, index) => (
                                <li key={index}>{change}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
            <script src='../../scripts/hideVersions.js'></script>
        </div>
    )
    // JavaScript code
}
export default TheUpdates