import React from 'react';
import './TheIntroduction.css'

//images
import googleplay from '../../assets/imgs/google_play@300x.png'
import appstore from '../../assets/imgs/app_store@300x.png'

import mobile from '../../assets/imgs/application_main_screen_new.png'

import fb from '../../assets/imgs/ikona_fcb@300x.png'
import ytb from '../../assets/imgs/ikona_youtube@300x.png'
import twitter from '../../assets/imgs/ikona_twiter@300x.png'

import vlak from '../../assets/imgs/vlak_v_kruhu_idolka@300x.png'
import bus from '../../assets/imgs/bus_v_kruhu_idolka@300x.png'
import tramvaj from '../../assets/imgs/tramvaj_v_krzhu_idolka@300x.png'

interface Props {

}

const TheIntroduction: React.FC<Props> = () => {
    return (
        <div className='introduction pt-5'>
            <div className="container">
                <div className="row pt-2 pt-md-5 align-items-center">
                        <div className="col-lg-2 apps_icons text-center app_stores_icons">
                            <a href="https://play.google.com/store/apps/details?id=cz.oict.idolka" target="_blank" rel="noreferrer"><img className="pt-0 pb-lg-2 lazy" src={googleplay} alt='googleplay'/></a>
                            <a href="https://apps.apple.com/us/app/idolka/id1576669886" target="_blank" rel="noreferrer"><img className="pt-0 pt-lg-2 lazy" src={appstore} alt='appstore'/></a>
                        </div>

                        <div className="col-md-6 col-lg-4 d-none d-md-inline mobile">
                            <img className="lazy" src={mobile} alt=''/>
                        </div>

                        <div className="col-md-5 text-center headline-texts">
                            <h1>Idolka</h1>
                            <h2 className="text-uppercase">Mobilní aplikace <br/>pro cestování v IDOLu</h2>

                            <div className="pt-3 transport_icons">
                                <img className="lazy" src={vlak} alt='vlak'/>
                                <span className="pl-3"></span>
                                <img className="lazy" src={bus} alt='autobus'/>
                                <span className="pl-3"></span>
                                <img className="lazy" src={tramvaj} alt='tramvaj'/>
                            </div>
                        </div>

                        <div className="col-md-1 pt-4 pt-md-0 text-center social_networks_icons">
                            <a href="https://www.facebook.com/idollk" target="_blank" rel="noreferrer" title="Facebook">
                                <img className="p-1 lazy" src={fb} alt='facebook'/>
                            </a>

                            <a href="https://www.youtube.com/channel/UCPsX6kJ4IEt7j6I4r7VKI3Q" target="_blank" rel="noreferrer" title="Youtube">
                                <img className="p-1 lazy" src={ytb} alt='youtube'/>
                            </a>

                            <a href="https://twitter.com/idoldoprava" target="_blank" rel="noreferrer" title="Twitter">
                                <img className="p-1 lazy" src={twitter} alt='twitter'/>
                            </a>
                        </div>
                    </div>
                <div className="row pt-4 pb-4"></div>
            </div>
        </div>
    )
}
export default TheIntroduction
