// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inline {
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 8px;
    vertical-align: middle;
    text-align: left;
    width: 700px;
}
.changes {
    width: 600px;
}
.tag-android {
    background-color: #a4c639;
    text-align: left;
}

.tag-ios {
    background-color: #007bff;
    text-align: left;
}

.title {
    display: inline-block;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    text-align: left;
}
.version-tag {
    display: inline-block;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    margin-right: 8px;
}
.container ul li {
    text-align: left;
}
.date-tag {
    background-color: #777;
    text-align: left;
}
.content-field {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/view/updates/TheUpdates.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;IACxB,gBAAgB;AACpB;AACA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;IACxB,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".inline {\n    display: inline-block;\n    margin-top: 40px;\n    margin-bottom: 8px;\n    vertical-align: middle;\n    text-align: left;\n    width: 700px;\n}\n.changes {\n    width: 600px;\n}\n.tag-android {\n    background-color: #a4c639;\n    text-align: left;\n}\n\n.tag-ios {\n    background-color: #007bff;\n    text-align: left;\n}\n\n.title {\n    display: inline-block;\n    line-height: 1;\n    text-align: center;\n    white-space: nowrap;\n    vertical-align: baseline;\n    text-align: left;\n}\n.version-tag {\n    display: inline-block;\n    padding: 3px 7px;\n    font-size: 12px;\n    font-weight: 600;\n    line-height: 1;\n    color: #fff;\n    text-align: center;\n    white-space: nowrap;\n    vertical-align: baseline;\n    border-radius: 10px;\n    margin-right: 8px;\n}\n.container ul li {\n    text-align: left;\n}\n.date-tag {\n    background-color: #777;\n    text-align: left;\n}\n.content-field {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
