import React from 'react';

import './TheVop.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const TheVop: React.FC = () => {
    return(
        <div id="vop" className="container">
            <div className="row">
                <div className="col-12 pt-3 pt-md-5 text-center">
                    <h2 className="montserrat-bold headline">Obchodní podmínky užívání mobilní aplikace Idolka </h2>
                </div>

                <div className="col-12 pt-3 pt-md-5 vop-text">
                    <p>Platné od 1.6.2023</p>

                    <ol>
                        <li>Obecná ustanovení
                            <ol>
                                <li>Společnost Operátor ICT a.s., zapsaná v obchodním rejstříku vedeném Městským soudem v Praze pod sp. zn. B 19676, jakožto vydavatel a provozovatel mobilní aplikace Idolka (dále jen ,,Provozovatel") stanoví tyto Obchodní podmínky užívání mobilní aplikace Idolka (dále jen „Podmínky“). Mobilní aplikace Idolka je nedílnou součástí Multikanálového odbavovacího systému (dále jen „MOS“) prostřednictvím kterého Provozovatel poskytuje služby odbavení ve veřejné hromadné dopravě. Další ze součástí MOS je zejména portál MOS, prostřednictvím kterého je provozován e-shop MOS.</li>
                                <li>Mobilní aplikace Idolka je klientskou aplikací určenou pro odbavení cestujících ve veřejné hromadné dopravě, prostřednictvím které má cestující přístup zejména k následujícím službám:
                                    <ol>
                                        <li>vyhledávání spojení,</li>
                                        <li>nákup a správa jednotlivých elektronických jízdních dokladů,</li>
                                        <li>správa platebních karet, ve smyslu přidružování platebních karet ke konkrétním účtům,</li>
                                        <li>využití mobilní aplikace Idolka jako identifikátor pro dlouhodobé jízdné</li>
                                        <li>nákup dlouhodobého jízdného a správa identifikátorů</li>
                                    </ol>
                                </li>
                                <p>(dále jen „Služby“).</p>
                                <li>Prostřednictvím mobilní aplikace Idolka jsou poskytovány Služby v souladu s integrovaným dopravním systém Libereckého kraje (dále jen „Tarif IDOL“), který stanovuje v integrovaném dopravním systému Libereckého kraje (dále jen „IDOL“) způsob a postup při uplatňování cen jízdného v hromadné dopravě osob na území Libereckého kraje a na území krajů v rámci IDOL. Tarif IDOL vyhlašuje organizátor a koordinátor IDOL, KORID LK, s.r.o. (dále jen „KORID“) a jeho plné znění je dostupné na adrese www.iidol.cz.</li>
                                <li>Právní vztah mezi Provozovatelem a klientem vzniká instalací mobilní aplikace Idolka a řídí se těmito Podmínkami, jakožto rámcovou smlouvou, Tarifem IDOL a právním řádem České republiky, zejména zákonem 89/2012 Sb., občanským zákoníkem, v platném znění. Souhlas s těmito Podmínkami je vyžadován již při instalaci mobilní aplikace Idolka, neboť tímto krokem je vůči Provozovateli učiněn návrh na uzavření smlouvy. Provozovatelův souhlas s uzavřením smlouvy je dán v okamžiku řádného provedení instalace mobilní aplikace Idolka.</li>
                                <li>Zakoupením elektronického jízdního dokladu nevzniká právní vztah přepravní smlouvy, tento se řídí Smluvními přepravními podmínkami IDOL vyhlašovaných KORID LK, s.r.o.. Provozovatel se také nestává účastníkem přepravní smlouvy.</li>
                                <li>Provozovatel si vyhrazuje právo zablokovat užívání mobilní aplikace Idolka, dílčí účet v ní založený, nebo i jednotlivý elektronický jízdní doklad v případě, že tato je použita v rozporu s těmito Podmínkami nebo zneužita pro plnění odlišné od Služeb dle odst. 1.2 tohoto článku Podmínek. Za užívání mobilní aplikace Idolka v rozporu s Podmínkami se považují také neautorizované zásahy do její funkčnosti.</li>
                            </ol>
                        </li>
                        <li>Definice pojmů
                            <ol>
                                <li>Provozovatel mimo obecné zkratky zavedené těmito Podmínkami v jejich dílčích ustanoveních výslovně upravuje následující vybrané pojmy životních cyklů funkcionalit mobilní aplikace Idolka. Definice pojmů již obsahují práva a povinnosti klientů ve vztahu k danému pojmu a mohou být dále doplněny nebo zpřesněny v rámci ostatních ustanovení těchto Podmínek.</li>
                                <li>„Uživatel“ představuje souhrnný pojem pro klienty, kteří si stáhli mobilní aplikaci Idolka do příslušného zařízení se systémem Android nebo iOS. „Registrovaný Uživatel“ je Uživatel, který přistoupil k vytvoření svého účtu. Registrovaný uživatel získává vytvořením Uživatelského účtu další výhody spočívající například v zasílání daňových dokladů prostřednictvím e-mailu, zvýšení bezpečnosti Jízdenek, přenášení Jízdenek na jiná Zařízení (ať již na Zařízení jiného Registrovaného uživatele či na jiné Zařízení z důvodu ztráty, odcizení, či zničení Zařízení), aj.</li>
                                <li>„Zařízení“ je hardwarový nosič Uživatele, na kterém je nainstalovaná mobilní aplikace Idolka.</li>
                                <li>„Jízdenka“ je elektronický jízdní doklad, jímž cestující prokazuje úhradu jízdného/přepravného při přepravní kontrole, dle přepravních podmínek dopravců a Tarifu IDOL. „Elektronickým záznamem“ je jízdenka odpovídajícího formátu vygenerovaná systémem MOS prostřednictvím mobilní aplikace Idolka.</li>
                                <li>„Dlouhodobý kupon“ představuje informaci ohledně existence dlouhodobého jízdního dokladu Uživatele, který byl zakoupen a přiřazen v rámci registrovaného účtu Uživatele v e-shopu IDOL, mobilní aplikaci Idolka, či jiným způsobem. Import této informace do mobilní aplikace Idolka provede Uživatel buď ručním zadáním, nebo přihlášením svých registračních údajů e-shopu IDOL v mobilní aplikaci Idolka. Tímto krokem nedochází k aktivaci mobilní aplikace Idolka jako identifikátoru k uloženému Dlouhodobému kuponu. Pro aktivaci této služby je Uživatel povinen v mobilní aplikaci Idolka přímo potvrdit možnost registraci Identifikátoru MA a následně k němu přiřadit dlouhodobé kupony v souladu s čl. 3 těchto Podmínek.</li>
                                <li>„Neaktivovaná jízdenka“ je Jízdenka zakoupená v mobilní aplikaci Idolka, která ještě nebyla Uživatelem aktivovaná.</li>
                                <li>„Aktivovaná jízdenka“ je časově omezený stav Jízdenky v mobilní aplikaci Idolka, který nastane po kliknutí na tlačítko „Aktivovat“. Jedná se o Jízdenku se základními údaji o trase, tarifu, ceně a délce platnosti, která ještě nedisponuje zobrazenými prvky kontroly (QR, vizuální informace) a tudíž není platná. K aktivaci Jízdenky musí dojít v okamžiku, kdy má Uživatel přístup k datovým službám.</li>
                                <li>„Jízdenka před platností“ je časově omezený stav jízdenky v mobilní aplikaci Idolka, který nastane v případě, že Uživatel předem zvolí začátek platností jízdenky v budoucnu (odložený začátek). Jízdenkou v tomto stavu uživatel prokazuje nevratnou aktivaci jízdenky, ke které dojde v budoucnu k nastavenému času. Jízdenka v tomto stavu obsahuje všechny prvky vedoucí k odbavení v přepravní kontrole, nicméně sama o sobě není před nastaveným budoucím časem platným dokladem a v případě přepravní kontroly se Uživatel musí prokázat jiným platným a aktivovaným jízdním dokladem. Typicky se Jízdenka před platností využívá v situacích, kdy část cesty je pokryta Dlouhodobým kupónem a část cesty Jízdenkou zakoupenou v mobilní aplikaci Idolka, která představuje Jízdenku před platností, která se automaticky aktivuje k nastavenému času.
                                    <br/><br/>Minimální doba pro odloženou platnost je 60 sekund, v souladu s bodem 2.7.
                                    <br/>Jízdenku před platností již nelze deaktivovat.</li>
                                <li>„Platná jízdenka“ je Jízdenka obsahující všechny informace vedoucí k úspěšnému odbavení v přepravní kontrole. Platnost Jízdenky nastává po uplynutí ochranného období, tj. v časovém odstupu šedesáti (60) sekund od aktivace Jízdenky, tato doba je mobilní aplikací Idolka nastavena automaticky a Provozovatel si vyhrazuje právo ji zkrátit, nejedná-li se o Jízdenku před platností, kde je platnost nastavena způsobem dle odst. 2.6 těchto Podmínek výše.</li>
                                <li>„Spotřebovaná jízdenka“ je stav Jízdenky po uplynutí časové platnosti Jízdenky, bez zobrazených prvků kontroly.</li>
                                <li>„Archivovaná jízdenka“ je Spotřebovaná jízdenka uložená v systému mobilní aplikace Idolka. Po uplynutí lhůty 3 měsíců již není dostupná a viditelná v mobilní aplikaci Idolka.</li>
                                <li>„Přenesená jízdenka“ je taková Jízdenka, kterou Registrovaný uživatel "A" odeslal Registrovanému uživateli "B". V Zařízení původního Registrovaného uživatele, který Jízdenku zakoupil, se tato zobrazí ve stavu, ze kterého ji nelze aktivovat a je jednoznačně uvedeno, že se jedná o Jízdenku, která byla odeslána a přenesena na jiné Zařízení.</li>
                                <li>„Neplatná jízdenka“ je taková Jízdenka, která:
                                    <ol>
                                        <li>nebyla aktivována,</li>
                                        <li>byla použita před uplynutím ochranné doby a započetím platnosti,</li>
                                        <li>byla použita mimo rozsah zónově relační platnosti, pro který je platná,</li>
                                        <li>pozbyla její časové platnosti uplynutím,</li>
                                        <li>byla pořízena jiným způsobem než z mobilní aplikace Idolka,</li>
                                        <li>byla neoprávněně jakkoliv pozměněna, upravena nebo zkopírována,</li>
                                        <li>nelze-li z příčin na straně cestujícího ověřit její platnost,</li>
                                        <li>není při přepravní kontrole nalezena,</li>
                                        <li>nebyla při elektronické kontrole (QR kód, NFC kontrola, kontrolní vizuální obrazec, on-line dotaz) uznána za platnou,</li>
                                        <li>byla aktivována až v době provádění přepravní kontroly, nebo až po výzvě pracovníka přepravní kontroly.</li>
                                    </ol>
                                </li>
                                <li>„Zneplatněná jízdenka“ je anulovaná Jízdenka na základě akce provedené výhradně Provozovatelem buď na žádost Uživatele, provedenou prostřednictvím mobilní aplikace Idolka, Infolinky či Přepážek, nebo z důvodů uvedených v odst. 1.6 těchto Podmínek. Takovou Jízdenku nelze aktivovat a nenastává její platnost.</li>
                                <li>„Zónově relační platnost Jízdenky“ je vymezení územní platnosti Jízdenky za pomoci výslovného uvedení tarifních zón při aktivaci Jízdenky.</li>
                                <li>„Uživatelský účet“ je účet Registrovaného uživatele v mobilní aplikaci Idolka. Uživatelský účet obsahuje mimo jiné jméno a heslo Registrovaného uživatele a tomuto umožňuje využíval mobilní aplikaci Idolka v plném rozsahu, tj. zejména funkcionality správu Jízdenek a správu přidružených platebních karet.</li>
                                <li>„Identifikátor instalace aplikace“ je jednoznačně unikátní řetězec znaků reprezentující a identifikující konkrétní instalaci mobilní aplikace Idolka na konkrétním Zařízení. Identifikátor instalace aplikace se Uživateli zobrazí při instalaci. Uživatel si identifikátor instalace aplikace uchovává pro jakýkoliv budoucí úkon vztahující se k jeho instalaci mobilní aplikace Idolka a poskytování Služeb.</li>
                                <li>„Identifikátor“ je označení pro entitu, který slouží k identifikaci Uživatele, respektive k ověření zakoupení dlouhodobého kuponu daným Uživatelem. Identifikátor je tedy nepostradatelnou součástí při odbavení nebo při přepravní kontrole, kdy je Uživatel povinen přiložit tento Identifikátor k odbavovacímu nebo kontrolnímu zařízení, které následně zkontroluje, zdali má Uživatel zakoupen platný elektronický jízdní doklad.
                                <br/>
                                Ke dni nabytí účinnosti těchto Podmínek je možné využít jako Identifikátor MA Idolka, od 1.9.2023 kartu Opuscard+, Lítačku, InKartu, bankovní karty MasterCard a Visa.</li>
                                <li>„Identifikátor MA“ je jedním z Identifikátorů, který mohou Uživatelé využít v IDOL. Identifikátor MA je unikátní kombinací informací identifikující konkrétní instalaci mobilní aplikace Idolka spolu s informací o konkrétním Zařízení a konkrétním registrovaném uživateli. V případě, že dojde ke změně zařízení, reinstalaci mobilní aplikace Idolka nebo přihlášení jiného Registrovaného Uživatele, mění se i výsledný Identifikátor MA.</li>
                                <li>„Informační služby“ poskytuje Provozovatel Uživatelům a široké veřejnosti a tyto se dále člení na následující informační kanály:
                                    <ol>
                                        <li>„Infolinka“ je Informační služba poskytovaná prostřednictvím placené telefonické linky, dostupné na kontaktech uvedených přímo v mobilní aplikaci Idolka.</li>
                                        <li>„Web“ je Informační služba poskytovaná na internetových stránkách Vydavatele umožňující dálkový přístup, a to na adrese idolka.cz.</li>
                                        <li>„Přepážky“ jsou osobním kontaktním místem nacházejícím se v Evropském domě, U Jezu 525/4, Liberec 1. Provozovatel si vyhrazuje právo rozšířit počet Přepážek i na jiných kontaktních místech a tyto nové Přepážky oznámit Uživatelům a široké veřejnosti na Webu.</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li> Využití mobilní aplikace jako Identifikátoru
                            <ol>
                                <li>Mobilní aplikaci Idolka lze využít jako Identifikátor MA v IDOL, a to za splnění předpokladů stanovených těmito Podmínkami.</li>
                                <li>Identifikátor MA je vždy Typ I – Osobní identifikátor s evidencí (dle Tarifu IDOL). Pokud Uživatel má zájem využít Identifikátor MA jako svůj Identifikátor, musí poskytnou své osobní údaje, včetně fotografie.</li>
                                <li>Identifikátor MA nelze využít jako identifikátor pro dlouhodobý přenosný kupon.</li>
                                <li>Registrace Identifikátoru MA k Uživatelskému účtu je možné pouze v rámci mobilní aplikaci Idolka, nikoliv na webu eshop.iidol.cz. Podmínkou pro registraci Identifikátoru je přihlášení Uživatelským účtem a připojení k internetu.</li>
                                <li>Veškerou následnou správu, tj.blokaci, přejmenování, přiřazení Dlouhodobého kuponu k Identifikátoru MA provádí Uživatel jak v rámci webu eshop.iidol.cz, tak v rámci mobilní aplikaci Idolka.</li>
                                <li>Odbavení Identifikátoru MA může probíhat následujícími způsoby:
                                    <ul>
                                        <li>kontrola QR kódu,</li>
                                        <li>pomocí NFC (od data vyhlášení).</li>
                                        <li>Online dotaz</li>
                                    </ul>
                                </li>
                                <li>Identifikátor MA představuje unikátní kombinaci informací identifikující konkrétní instalaci mobilní aplikace Idolka spolu s informací o konkrétním Zařízení a Uživateli. Z toho plyne, že:
                                    <ol>
                                        <li>V případě, že dojde k odinstalování mobilní aplikace Idolka na daném Zařízení, není možné se pomocí tohoto Zařízení odbavit.</li>
                                        <li>V případě, že dojde k reinstalaci mobilní aplikace Idolka, tj. odinstalování a jejímu opětovnému nainstalování na stejné Zařízení, může mobilní aplikace Idolka požadovat registraci nového Identifikátoru, který bude reprezentovat konkrétní Zařízení a novou instalaci mobilní aplikace Idolka. V tomto případě již není možné použít původní Identifikátor MA. Pro úspěšné odbavení novým Identifikátorem MA je nezbytné převést na tento Identifikátor MA všechny dotčené Dlouhodobé kupony. Po převedení kuponu k Identifikátoru MA není (stejně jako na ostatní identifikátory) možné odbavení původním identifikátorem.
                                        <br/><br/>
                                        Pokud se po reinstalaci aplikace zobrazí v záložce Kupóny (sekce Jízdenky) QR kód reprezentující identifikátor, není již potřeba opětovná registrace.
                                        </li>
                                        <li>V případě, že se Uživatel přihlásí svým Uživatelským účtem z eshop.iidol.cz na jiném Zařízení, než na kterém došlo k registraci nového Identifikátoru MA, musí Uživatel na tomto zařízení registrovat nový Identifikátor MA, který bude reprezentovat nové Zařízení a konkrétní instalaci mobilní aplikace Idolka. Pro úspěšné odbavením novým Identifikátorem MA je nezbytné převést na tento Identifikátor MA všechny dotčené Dlouhodobé kupony.</li>
                                        <li>V případě, že Uživatel pozbyde dispozici se Zařízením, které využíval spolu s nainstalovanou mobilní aplikací Idolka jako Identifikátor MA (ztráta, odcizení), musí Uživatel postupovat totožně, jako v bodu 3.7.3 těchto Podmínek.</li>
                                    </ol>
                                </li>
                                <li>V případě přiřazení Dlouhodobého kupónu k Identifikátoru MA se tento Identifikátor stává jediným Identifikátorem, kterým je možné prokázat zakoupení Dlouhodobého kupónu. Tarif IDOL nepřipouští stav, kdy by bylo možné pro jeden Dlouhodobý kupon využít při přepravní kontrole více Identifikátorů v jeden okamžik., například: kartu Opuscard+ a Identifikátor MA.</li>
                                <li>Uživatel, který se prokazuje při přepravní kontrole Identifikátorem MA je povinen dbát na to, aby Zařízení bylo funkční, nepoškozené a mohla tak být provedena přepravní kontrola. Je-li Identifikátor MA Uživatelem k přepravní kontrole předložen na Zařízení, které výše uvedené parametry nesplňuje a přepravní kontrola není úspěšná, nese Účastník plnou odpovědnost a není oprávněn k reklamaci.</li>
                                <li>Při přepravní kontrole je Uživatel povinen být v mobilní aplikaci Idolka přihlášen pod svým Uživatelským účtem tak, aby mohl zobrazit svoje platné Identifikátory/Dlouhodobé kupóny.</li>
                                <li>V případě, že uživatel provede nákup dlouhodobého kupónu mimo aplikaci Idolka, ale zvolí si ji jako identifikátor, je povinen zajistit datovou komunikaci této mobilní aplikace. Jakmile tato datová komunikace proběhne, zobrazí se v mobilní aplikaci Idolka informace o zakoupeném kupónu (V sekci Jízdenky-Kupóny) a teprve tehdy je možné se Identifikátorem MA odbavit. Pokud po nákupu dlouhodobého kupónu nedojde k datové komunikaci mobilní aplikace Idolka, nelze garantovat úspěšné odbavení.</li>
                            </ol>
                        </li>
                        <li>Nákup Jízdenky a podmínky jejího používání
                            <ol>
                                <li>Přehled Jízdenek dle Tarifu IDOL, které lze prostřednictvím mobilní aplikace Idolka zakoupit, včetně jejich cen, je Provozovatelem uveřejněn jak přímo v mobilní aplikaci Idolka, tak i na Webu. Provozovatel si vyhrazuje právo portfolio a ceny těchto Jízdenek měnit, a to v závislosti na aktualizaci Tarifu IDOL. Změny jsou Provozovatelem oznamovány přímo v mobilní aplikaci Idolka a na Webu.</li>
                                <li>Nákup Jízdenky může být Uživatelem proveden na základě doporučení, vyplývajícího z funkcionality vyhledávání spojení. Při vyhledávání spojení je Uživateli zobrazena doporučená délky cesty a doporučené jízdné dle Tarifu IDOL.  Pokud má Uživatel v mobilní aplikaci Idolka nastavenou informaci o dlouhodobých kupónech, bere se tato informace v potaz při doporučení jízdného. V takovém případě má Uživatel 2 možnosti nákupu:
                                    <ol>
                                        <li>„Jízdenka pro Vás“ je doporučená Jízdenka na konkrétní vyhledané spojení, kdy část trasy pokrývá dlouhodobý kupón a zbylou část Jízdenka v mobilní aplikaci Idolka. Pokud Uživatel zvolí tuto Jízdenku, musí se při odbavení prokázat dlouhodobým kupónem společně s Jízdenkou aktivovanou v mobilní aplikaci Idolka. Mobilní aplikace Idolka neslouží k prokázání dlouhodobého kupónu a je nutné mít identifikátor s elektronickým dlouhodobým kupónem nebo papírový dlouhodobý kupón.
                                            <br/><br/>Doporučená jízdenka pokrývá všechna potřebné zóny daného spojení, která nejsou nastavena jako předplacená a časovou platnost Jízdenky od času daného spoje v poslední zastávce z předplacených pásem.</li>
                                        <li>„Alternativní jízdenka“ je Jízdenka, která pokryje celou vyhledanou trasu a nebere v potaz existenci dlouhodobého kupónu.
                                            <br/><br/>Tato Služba v souladu s odst. 6.4 těchto Podmínek využívá data ze zdrojů třetích stran, Provozovatel tak nezodpovídá za jejich přesnost, úplnost a správnost. V této souvislosti Provozovatel výslovně upozorňuje, že v případě selhání správnosti zdrojů třetích stran může dojít zejména k vlivu na Jízdenku před platností, jejíž automatická aktivace je vázaná na čas, vyplývající právě z externího zdroje. V případě, kdy se reálné transportní časy prostředku veřejné hromadné dopravy vychýlí od zdroje třetí strany (zejména jízdního řádu) tak může dojít k opožděnému nabytí platnosti Jízdenky před platností, a to v případě předstihu prostředku veřejné hromadné dopravy před jízdním řádem, či naopak k předčasnému nabytí platnosti Jízdenky před platností, a to v případě zpoždění prostředku veřejné hromadné dopravy oproti jízdním řádům. V těchto případech Provozovatel za komplikace v rámci odbavovacího procesu neodpovídá, jelikož se nejedná o chybu v mobilní aplikaci Idolka, ale odpovědnost třetí strany.
                                        </li>
                                    </ol>
                                </li>
                                <li>Uživatel je povinen použít Jízdenku pouze v souladu s Tarifem IDOL a Smluvními přepravními podmínkami IDOL, zejména je povinen nastoupit do dopravního prostředku nebo vstoupit do přepravního prostoru pouze s Platnou jízdenkou uloženou pouze na Zařízení, na kterém byla aktivována.</li>
                                <li>Uživatel je odpovědný za správnost zadání zónově relační platnosti své Jízdenky.</li>
                                <li>Provozovatel výslovně upozorňuje, že pokud Uživatel používá mobilní aplikaci Idolka na Zařízení, u kterého byly provedeny změny nastavení, tzv. „root“ (tj. změny, které mohou vést k narušení bezpečnosti daného Zařízení např. snížením jeho odolnosti proti škodlivému softwaru), ztrácí možnost obnovení nezobrazených Jízdenek, přenos Jízdenky na jiné Zařízení v případě ztráty nebo poškození či jakékoliv jiné reklamace. Provozovatel může uplatnit postup dle odst. 1.6 těchto Podmínek.</li>
                                <li>Nákup dlouhodobých kupónů
                                    <ol>
                                        <li>Pro nákup elektronických jízdních dokladů je uživatel povinen přiřadit ke svému uživatelskému účtu platný identifikátor, který bude využívat. Na základě typu přiřazeného identifikátoru může uživatel zakoupit určitý typ elektronického jízdního dokladu.</li>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td></td>
                                                    <td colSpan={2}>Typ identifikátoru</td>
                                                </tr>
                                                <tr>
                                                    <td>Typ elektronického jízdního dokladu</td>
                                                    <td>Identifikátor typ I. (Opuscard+, In Karta, bankovní platební karta s evidencí, mobilní aplikace Idolka)</td>
                                                    <td>Identifikátor III. (Opuscard+ anonymní, bankovní platební karta anonymní)</td>
                                                </tr>
                                                <tr>
                                                    <td>Občanské jízdné</td>
                                                    <td>ANO</td>
                                                    <td>NE</td>
                                                </tr>
                                                <tr>
                                                    <td>Slevové jízdné</td>
                                                    <td>ANO</td>
                                                    <td>NE</td>
                                                </tr>
                                                <tr>
                                                    <td>Přenosné jízdné</td>
                                                    <td>NE</td>
                                                    <td>ANO</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <li>Elektronické jízdní doklady není možné zakoupit v jiné než elektronické formě, duplikáty v papírové formě není možné vystavit.</li>
                                        <li>Před dokončením nákupu je uživatel povinen zkontrolovat správnost všech zadaných hodnot pro elektronický jízdní doklad.</li>
                                        <li>Vrácení elektronického jízdního dokladu se řídí Tarifem IDOL</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>Přepravní kontrola
                            <ol>
                                <li>Přepravní kontrola Jízdenek probíhá ověřováním dat, které Jízdenka obsahuje. Průběh přepravní kontroly stanoví Smluvní přepravní podmínky IDOL. Dopravce nebo jím pověřená osoba je oprávněna provádět přepravní kontrolu následujícími způsoby, a to buď kumulativně nebo i jen jedním z nich:</li>
                                    <ol>
                                        <li>kontrola NFC (bezdrátové),</li>
                                        <li>kontrola QR kódu,</li>
                                        <li>vizuální kontrola Jízdenky,</li>
                                        <li>Online dotaz,</li>
                                        <li>Interaktivní prvek.</li>
                                    </ol>
                                
                                <li>Uživatel, který se prokazuje při přepravní kontrole platnou Jízdenkou zakoupenou v mobilní aplikaci Idolka, je povinen předložit tuto Jízdenku ke kontrole na funkčním a nepoškozeném Zařízení tak, aby mohla být provedena řádná přepravní kontrola. Je-li Jízdenka Uživatelem k přepravní kontrole předložena na Zařízení, které výše uvedené parametry nesplňuje a přepravní kontrola není úspěšná, nese Účastník plnou odpovědnost a není oprávněn takovouto Jízdenku reklamovat.</li>
                                <li>Odbavení tzv. Jízdenky pro Vás probíhá s ohledem na existenci jiného identifikátoru s dlouhodobým kuponem způsobem popsaným v pododst. 4.2.1 těchto Podmínek výše.</li>
                                <li>Přepravní kontrola Dlouhodobých kupónů přiřazených k Identifikátoru mobilní aplikace probíhá obdobným způsobem, jako kontrola krátkodobých Jízdenek. Všechny způsoby jsou vyjmenované v bodě 3.6. Podrobná pravidla pro kontrolu jsou popsány v bodě 3.9.</li>
                            </ol>
                        </li>
                        <li>Platební a reklamační podmínky
                            <ol>
                                <li>Cena Jízdenky je stanovena Tarifem IDOL v korunách českých včetně DPH. Má-li Uživatel v souladu s Tarifem IDOL nárok na slevu, uplatňuje ji již při nákupu Jízdenky. Provozovatel vůči Uživateli neúčtuje žádné další související poplatky. V ceně nákupu není zahrnuta cena případných datových služeb, které Uživatel používá pro přístup k mobilní aplikaci Idolka.</li>
                                <li>Po uhrazení příslušné částky Uživatelem vystaví systém MOS Jízdenku a zašle ji prostřednictvím datové komunikace do mobilní aplikace Idolka. Jízdenka obsahuje informace o ceně, o časové a zónově relační platnosti, identifikační kód a kontrolní prvky pro přepravní kontrolu.</li>
                                <li>Jízdenka zakoupená prostřednictvím mobilní aplikace Idolka je uložena v elektronickém systému Provozovatele pro případnou pozdější kontrolu ze strany dopravce i Uživatele.</li>
                                <li>V mobilní aplikaci Idolka lze platit pouze bezhotovostně platebními kartami prostřednictvím platební brány. Registrovaný uživatel je oprávněn využít funkcionality správy platebních karet, v rámci které je mu Provozovatelem umožněno k jeho účtu přiřadit konkrétní platební karty nebo si vybranou platební kartu zapamatovat pro další platby. Provozovatel dále umožňuje platby i bezhotovostně prostřednictvím služeb ApplePay, Google Pay (ode dne vyhlášení). Provozovatel si dále vyhrazuje právo doplnit i další bezhotovostní platební způsoby, tyto oznámí Uživatelům a široké veřejnosti na Webu.</li>
                                <li>Provozovatel Uživatelům vydává za nákup Jízdenky daňové doklady dle zákona 235/2004 Sb., o dani z přidané hodnoty, v z. p. p. Daňový doklad zpětně za dobu 10 let je možné vyžádat prostřednictvím e-mailu uvedeného na Webu či přes Infolinku.</li>
                                <li>Provozovatel výslovně upozorňuje na výhradu práva dle odst. 1.6 čl. 1 těchto Podmínek, které se vztahuje i na případ, kdy je Provozovatelem zjištěno, že k zakoupení Jízdenky prostřednictvím mobilní aplikace Idolka došlo na základě protiprávního jednání Uživatele či třetí osoby, nebo je zakoupená Jízdenka použita Uživatelem či třetí osobou za účelem protiprávního jednání.</li>
                                <li>Uživatel je oprávněn reklamovat Jízdenku, a to z důvodu, kdy Jízdenka vykazuje vady v důsledku provozu mobilní aplikace Idolka. Za tyto vady se považuje zejména:
                                    <ol>
                                        <li>Po zakoupení se Jízdenka nezobrazí v mobilní aplikaci Idolka.</li>
                                        <li>Jízdenku nelze aktivovat.</li>
                                        <li>Jízdenka po aktivaci nenabyla platnosti.</li>
                                        <li>Jízdenka vykazuje všechny potřebné znaky pro řádné provedení přepravní kontroly a přesto nebyla kontrola úspěšná.</li>
                                    </ol>
                                </li>
                                <li>Uživatel není oprávněn reklamovat události a úkony související s přepravou, výší ceny Jízdenky dle Tarifu IDOL, správnost zadání zónově relační platnosti Jízdenky a volbu jízdního tarifu zakoupené Jízdenky, pokud byla zakoupena na základě doporučení v rámci funkcionality vyhledávání spojení dle odst. 1.2.1 těchto Podmínek.</li>
                                <li>Uživatel uplatňuje své právo reklamace vůči Provozovateli ve lhůtě 30 dnů od vzniku reklamačního důvodu.</li>
                                <li>V souladu s ustanovením § 2 odstavec 1, písmeno a) zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů, je spotřebitelem fyzická osoba, která nejedná v rámci své podnikatelské činnosti nebo v rámci samostatného výkonu svého povolání. Je-li Uživatel-spotřebitelem, Provozovatel o reklamaci rozhodne ihned, ve složitých případech do tří pracovních dnů. Do této lhůty se nezapočítává doba přiměřená podle druhu služby potřebná k odbornému posouzení vady.</li>
                                <li>Reklamaci včetně odstranění vady Provozovatel vyřídí bez zbytečného odkladu, nejpozději do 30 dnů ode dne uplatnění reklamace. Lhůtu 30 dnů je možné po uplatnění reklamace prodloužit po domluvě s Uživatelem – takové prodloužení nesmí být na dobu neurčitou nebo nepřiměřeně dlouhou. Po uplynutí lhůty, resp. prodloužené lhůty se má za to, že vada skutečně existovala a Uživatel má stejná práva, jako by se jednalo o vadu, kterou nelze odstranit.</li>
                                <li>Provozovatel v případě uznání reklamačního nároku provede reklamaci formou vrácení celé výše jízdného Uživateli, a to bezhotovostně na účet, ze kterého byla platba za Jízdenku provedena.</li>
                            </ol>
                        </li>
                        <li>Ochrana osobních údajů a datová politika
                            <ol>
                                <li>V rámci mobilní aplikace Idolka je Provozovatel oprávněn shromažďovat, zpracovávat a uchovávat osobní údaje Uživatelů, a to výlučně pro účely poskytování Služeb. Detailně jsou účely zpracování osobních údajů popsány v čl. 7.9 těchto Podmínek.</li>
                                <li>Provozovatel přijal veškerá taková opatření, zejména organizační, technická a personální, která zajišťují soulad poskytování Služeb mobilní aplikace Idolka s aktuálně platnou a účinnou právní úpravou vztahující se k ochraně osobních údajů a k ochraně dalších dat, která jsou v mobilní aplikaci Idolka uchovávána.</li>
                                <li>Uživatel může být osloven Provozovatelem k udělení svobodného souhlasu se zpracováním osobních údajů i pro jiné účely, tento však musí odpovídat právě aktuálně platné a účinné právní úpravě vztahující se k ochraně osobních údajů. Takto udělený souhlas má uživatel právo kdykoli odvolat.</li>
                                <li>Správcem osobních údajů je Liberecký kraj, U Jezu 642/2a, 461 80 Liberec 2, IČO: 70891508.</li>
                                <li>Zpracovatelem osobních údajů je KORID LK, s.r.o (organizátor a koordinátor IDOL) a Operátor ICT, a.s. (provozovatel aplikace Idolka)</li>
                                <li>Kontaktní údaje pověřence pro ochranu osobních údajů je dpo@korid.cz.</li>
                                <li>Operátor ICT, a.s. zpracovává data za následujícím účelem:
                                    <ul>
                                        <li>Prokázání totožnosti při nákupu jízdného, které je dle Tarifu nepřenosné</li>
                                        <li>Prokázání totožnosti cestujícího při odbavení v rámci systému IDOL</li>
                                        <li>Prokázání totožnosti pro případ reklamace a jiných nestandardních situací</li>
                                        <li>Dodržení platné legislativy, např. zákon o účetnictví</li>
                                    </ul>
                                </li>
                                <li>Ke zpracování osobních údajů dochází na základě:
                                    <ul>
                                        <li>Splnění smlouvy se subjektem údajů, tj. na základě článku 6 odst. 1 písm. b) NAŘÍZENÍ EVROPSKÉHO PARLAMENTU A RADY (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů).</li>
                                        <li>Na základě právních předpisů, např. zákon o účetnictví.</li>
                                        <li>Oprávněných zájmů správce a zpracovatele na uhrazení jízdného.</li>
                                    </ul>
                                </li>
                                <li>Při zpracování jsou dodržovány zásady stanovené obecným nařízením o ochraně osobních údajů v článku 5 odst. 1, přičemž správce odpovídá za jejich dodržování.</li>
                                <li>Operátor ICT zpracovává tyto osobní údaje: Jméno, Příjmení, datum narození, telefonní číslo, fotografii, email, a dále pro Registrovaného uživatele v mobilní aplikaci Idolka - jméno a heslo, identifikátor instalace aplikace, identifikátor uživatele, identifikátor MA.</li>
                                <li>Příjemci osobních údajů jsou dopravci pro odbavení cestujícího, zpracovatel jako provozovatel aplikace a dodavatel mobilní aplikace.</li>
                                <li>Osobní údaje během zpracování nejsou předávány do třetích zemí nebo mezinárodním organizacím.</li>
                                <li>Osobní údaje jsou automaticky mazány po 10 letech od konce platnosti posledního zakoupeného elektronického jízdného. Doba uchování je stanovena v souladu s platnou legislativou.</li>
                                <li>Uživatelé mohou dle obecného nařízení na ochranu osobních údajů uplatňovat práva subjektů údajů zejména požadovat od správce přístup k osobním údajům, jejich opravu nebo výmaz, případně omezení zpracování a práva na přenositelnost údajů.</li>
                                <li>V případě zpracování osobních údajů pro účely oprávněných zájmů správce nebo třetí strany může uživatel vznést námitku proti zpracování.</li>
                                <li>V případě zpracování osobních údajů na základě zákona není možné vymazat osobní údaje uživatele po dobu, po kterou je správce povinen dle zákona osobní údaje zpracovávat.</li>
                                <li>Uživatel má právo podat stížnost u Úřadu pro ochranu osobních údajů, se sídlem Pplk. Sochora 27, 170 00 Praha 7.</li>
                                <li>Poskytnutí osobních údajů je ze strany uživatele dobrovolné, nicméně nezbytné k uzavření a plnění smlouvy.</li>
                                <li>Mobilní aplikace Idolka využívá pro poskytování Služeb data ze zdrojů třetích stran, Provozovatel tak nezodpovídá za jejich přesnost, úplnost a správnost.</li>
                                <li>S ohledem na skutečnost, že mobilní aplikace Idolka je Provozovatelem distribuována ke stažení na Apple Store a Google Store, je Provozovatel povinen Uživatele seznámit s následujícími Zásadami ochrany osobních údajů poskytovatelů služeb Apple Store a Google Store:
                                    <ol>
                                        <li>pro Apple Store přístupné na stránkách <a href="http://www.apple.com/legal/privacy/cz/" target="_blank" rel="noreferrer">www.apple.com/legal/privacy/cz/</a></li>
                                        <li>pro Google Store přístupné na stránkách <a href="https://policies.google.com/privacy?hl=cs" target="_blank" rel="noreferrer">policies.google.com/privacy?hl=cs</a></li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>Závěrečná ustanovení
                            <ol>
                                <li>Vztahy a případné spory, které vzniknou na základě Rámcové smlouvy, budou řešeny výhradně podle práva České republiky a budou řešeny příslušnými soudy České republiky.</li>
                                <li>Případné spory mezi Provozovatelem a Uživatelem lze, v případě neúspěchu komunikace s Provozovatelem, řešit také mimosoudní cestou. V takovém případě Uživatel – spotřebitel může kontaktovat subjekt mimosoudního řešení sporu, kterým je například <a href="https://www.coi.cz/informace-o-adr/" target="_blank" rel="noreferrer">Česká obchodní inspekce</a> či spor řešit on-line prostřednictvím k tomu určené <a href="https://ec.europa.eu/" target="_blank" rel="noreferrer">ODR platformy</a>.</li>
                                <li>Tyto Podmínky jsou uveřejněny v českém jazyce.</li>
                                <li>Podmínky a veškerá další dokumentace související s mobilní aplikací Idolka jsou zveřejněny na Webu.</li>
                                <li>Provozovatel si vyhrazuje právo tyto Podmínky změnit. Účinnost změny nastane nejdříve pět (5) dní po jejím uveřejnění na Webu Provozovatele.</li>
                            </ol>
                        </li>
                    </ol>

                    <p>Tyto Podmínky nabývají účinnosti dne 1.6.2023</p>
                </div>
            </div>
        </div>
    )
}
export default TheVop