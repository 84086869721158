import React from 'react';
import './TheTravel.css'

//images
import phone from '../../assets/imgs/application_main_screen_new.png'

interface Props {

}

const TheTravel: React.FC<Props> = () => {
    return (
        <div id="travel">
            <div className="container">
                <div className="row pt-5 align-items-center text-center">
                    <div className="col-12 pb-2 pb-md-5 pt-0 pt-md-2">
                        <h2 id='about' className="montserrat-bold headline pb-4">CESTUJTE S MOBILNÍ APLIKACÍ,
                            <br/>
                            NIC VÍC K TOMU NEPOTŘEBUJETE
                        </h2>
                        <p className="montserrat-semibold font-weight-bold">S IDOLKOU v integrované dopravě Libereckého kraje</p>
                    </div>

                    <div className="col-md-4 pt-0 pt-md-5 pb-md-5">
                        <p><span className="montserrat-bold font-size-18 color-cred font-weight-bold">Spojení</span>
                            <br/>Vyhledání nejrychlejšího spojení
                            <br/>a vhodné jízdenky
                            <br/>na Vámi zvolenou trasu v síti IDOL.</p>

                        <p><span className="montserrat-bold font-size-18 color-cred font-weight-bold">Jízdenky</span>
                            <br/>Jednoduchý nákup jízdenek
                            <br/>s možností až 10 jízdenek najednou.</p>

                        <p><span className="montserrat-bold font-size-18 color-cred font-weight-bold">Mapa</span>
                            <br/>Zobrazení zastávek, kontaktních míst a parkování.</p>
                    </div>

                    <div className="col-md-4 d-none d-md-inline pb-md-5">
                        <img className="lazy phone" src={phone} alt=''/>
                    </div>

                    <div className="col-md-4 pt-0 pt-md-5  pb-md-5">
                        <p><span className="montserrat-bold font-size-18 color-cred font-weight-bold">Správa účtu</span>
                            <br/>Kompletní informace o účtu,
                            <br/>včetně možnosti nastavení
                            <br/>jednotlivých funkcí či uložení
                            <br/>platební karty pro nákup jízdenek.</p>

                        <p><span className="montserrat-bold font-size-18 color-cred font-weight-bold">Aktuality</span>
                            <br/>Upozornění na novinky v integrované <br /> dopravě.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TheTravel