import React from 'react';
import './ThePay.css'

//images
import image_right from '../../assets/imgs/cart_screen_new.png'
import image_left from '../../assets/imgs/qr_screen_neww.png'

interface Props {

}

const ThePay: React.FC<Props> = () => {
    return (
        <div id="pay_go">
            <div className="container">
                <div className="row pt-5 pb-5">
                    <div className="col-md-6 text-center text-md-right">
                        <p className="montserrat-semibold font-size-18 color-cred font-weight-bold pt-5 mb-0">ZAKUPTE A AKTIVUJTE JÍZDENKU</p>
                        <p className="montserrat-semibold font-weight-bold margin-bottom-05-rem">Rychlý nákup jízdenky</p>
                        <p>Nakupujte jízdenky s možností aktivace
                            <br/>až v případě potřeby. Zakoupit si můžete
                            <br/>až 10 jízdenek najednou.
                            <br/>V nabídce jsou relační
                            <br/>nebo celosíťové jízdenky.</p>
                    </div>

                    <div className="col-md-6 d-none d-md-inline text-left">
                        <img className="lazy phone-up" src={image_right} alt=''/>
                    </div>

                    <div className="col-md-4 d-none d-md-inline text-right">
                        <img className="lazy phone-down" src={image_left} alt=''/>
                    </div>

                    <div className="col-md-8 pt-5 pt-md-0 text-center text-md-left screenshot-happyway-text">
                        <p className="montserrat-semibold font-size-18 color-cred font-weight-bold mb-0">ŠŤASTNOU CESTU</p>

                        <p className="montserrat-semibold font-weight-bold margin-bottom-05-rem">S aktivovanou jízdenkou
                            <br/>můžete okamžitě vyrazit</p>
                            <p>Jízdenka má prodlevu jednu minutu od aktivace,
                            <br/>než začne skutečně platit.</p>

                        <p>Při přepravní kontrole revizorem, řidičem nebo
                            <br/>průvodčím se vždy prokazujte QR kódem nebo
                            <br/>grafickým obrazcem v aplikaci.</p>
                    </div>

                    <div className="pt-md-5"></div>
                </div>
            </div>
        </div>
    )
}
export default ThePay;