// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/mapa-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#travel {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: auto;
    background-position: 0%;
    background-position-x: center;
}

@media (min-width: 1200px){
    .travel-container {
        max-width: 1140px;
    }
}

.phone-center img {
    max-width: 450px;
}

.title {
    width: 100%;
}

.title h2 {
    font-size: 35px;
    font-weight: bold;
}

.title p {
    font-weight: 700;
}

.phone {
    max-width: 450px;
}

.travel-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    align-items: center;
    margin: 0 auto;
}

.info-left span {
    color: rgba(167, 20, 63, 1);
    font-size: 18px;
    font-weight: 700;
}

.info-right span {
    color: rgba(167, 20, 63, 1);
    font-size: 18px;
    font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/travel/TheTravel.css"],"names":[],"mappings":"AAAA;IACI,yDAA8D;IAC9D,qBAAqB;IACrB,uBAAuB;IACvB,6BAA6B;AACjC;;AAEA;IACI;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["#travel {\n    background-image: url('../../assets/imgs/mapa-background.png');\n    background-size: auto;\n    background-position: 0%;\n    background-position-x: center;\n}\n\n@media (min-width: 1200px){\n    .travel-container {\n        max-width: 1140px;\n    }\n}\n\n.phone-center img {\n    max-width: 450px;\n}\n\n.title {\n    width: 100%;\n}\n\n.title h2 {\n    font-size: 35px;\n    font-weight: bold;\n}\n\n.title p {\n    font-weight: 700;\n}\n\n.phone {\n    max-width: 450px;\n}\n\n.travel-container {\n    display: flex;\n    flex-wrap: wrap;\n    margin-right: -15px;\n    margin-left: -15px;\n    align-items: center;\n    margin: 0 auto;\n}\n\n.info-left span {\n    color: rgba(167, 20, 63, 1);\n    font-size: 18px;\n    font-weight: 700;\n}\n\n.info-right span {\n    color: rgba(167, 20, 63, 1);\n    font-size: 18px;\n    font-weight: 700;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
