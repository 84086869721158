// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/intro-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.introduction {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-size: auto;
    background-position-x: 0%;
    background-position-x: center;
    margin-top: -100px;
    color: white;
}

#welcome .mobile, #welcome .apps_icons, #welcome .headline-texts {
    padding-top: 5em;
}

.app_stores_icons img {
    width: 150px;
}
.mobile img {
    max-width: 450px;
}
.social_networks_icons img {
    width: 100%;
    max-width: 50px;
}

.transport_icons img {
    width: 100%;
    max-width: 60px;
}
#welcome .soon {
    font-size: 30px;
    font-weight: bold;
}

@media (min-width: 768px){
    .pt-md-5, .py-md-5 {
        padding-top: 3rem!important;
    }
}

@media (max-width: 768px){
    .pt-md-5, .py-md-5 {
        padding-top: 3rem!important;
    }
}

.pl-3 {
    padding-left: 1rem!important;
}

`, "",{"version":3,"sources":["webpack://./src/components/intro/TheIntroduction.css"],"names":[],"mappings":"AAAA;IACI,6DAAmE;IACnE,qBAAqB;IACrB,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;AACA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI;QACI,2BAA2B;IAC/B;AACJ;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".introduction {\n    background: url('../../assets/imgs/intro-background.png') no-repeat;\n    background-size: auto;\n    background-position-x: 0%;\n    background-position-x: center;\n    margin-top: -100px;\n    color: white;\n}\n\n#welcome .mobile, #welcome .apps_icons, #welcome .headline-texts {\n    padding-top: 5em;\n}\n\n.app_stores_icons img {\n    width: 150px;\n}\n.mobile img {\n    max-width: 450px;\n}\n.social_networks_icons img {\n    width: 100%;\n    max-width: 50px;\n}\n\n.transport_icons img {\n    width: 100%;\n    max-width: 60px;\n}\n#welcome .soon {\n    font-size: 30px;\n    font-weight: bold;\n}\n\n@media (min-width: 768px){\n    .pt-md-5, .py-md-5 {\n        padding-top: 3rem!important;\n    }\n}\n\n@media (max-width: 768px){\n    .pt-md-5, .py-md-5 {\n        padding-top: 3rem!important;\n    }\n}\n\n.pl-3 {\n    padding-left: 1rem!important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
