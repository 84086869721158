import TheNavigation from './components/navigation/TheNavigation'
import TheFooterForm from './components/footer/TheFooterForm';
import './App.css';
import { Route, Routes} from 'react-router-dom';
import { useEffect } from 'react';

//styles
import './components/cookie/CookieConsent.css'

//components
import CookieConsent from './components/cookie/CookieConsent'

//views
import Faq from './view/faq/Faq'
import Home from './view/Home'
import Gdpr from './view/gdpr/TheGdpr'
import Vop from './view/vop/TheVop'
import Updates from './view/updates/TheUpdates'

function App() {
  const elements = document.getElementsByClassName('version-tag');
    Array.from(elements).forEach((element: Element) => {
      if (element.textContent?.trim() === '') {
        (element as HTMLElement).style.display = 'none';
      }
  });
  const GTM = () => {
    useEffect(() => {
      const script = document.createElement('script');
      script.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l !== 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-T2WMKFS');
      `;
  
      document.head.appendChild(script);
  
      return () => {
        // Cleanup the script when the component unmounts
        document.head.removeChild(script);
      };
    }, []);
  
    return null;
  };
  
  return ( 
    <div className="App">
      <meta name="apple-idolka-app" content="app-id=1576669886"></meta>
      <header>
        <TheNavigation/>
      </header> 
      <Routes>
          <Route path='/' Component={Home}/>
          <Route path='/faq' Component={Faq}/>
          <Route path='/gdpr' Component={Gdpr}/>
          <Route path='/vop' Component={Vop}/>
          <Route path='/novinky' Component={Updates}/>
      </Routes>  
      <TheFooterForm></TheFooterForm>
      <CookieConsent/>
      <script src="jquery-3.5.1.min.js"></script>
      <GTM/>
    </div>
  );
}
export default App;
