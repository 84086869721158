import React, {useEffect, useState} from 'react'
import './Faq.css'

interface DataItem {
    id: number;
    attributes: {
      a: Array<string>;
      createdAt: string;
      publishedAt: string;
      q: string;
      updatedAt: string;
    };
}

const Napady: React.FC = () => {
    const [faqData, setFaqData] = useState<DataItem[]>([]);
    const get_link = `${process.env.REACT_APP_STRAPI_URL}faq-questions`;

    useEffect(() => {
        fetch(get_link)
        .then(response => response.json())
        .then(jsonData => {
            const dataArray: DataItem[] = jsonData.data;
            setFaqData(dataArray);
        })
        .catch(error => {
            console.log(error);
        });
    }, [get_link]);
    
    const renderHTML = (html: string) => {
        return { __html: html };
    };

    return(
        <div id='faq' className='container'>
            <div className='row'>
                <div className='col-12 pt-3 pt-md-5 text-center'>
                    <h2 className='montserrat-bold headline'>Často kladené dotazy</h2>
                </div>
                <div className='col-12 pt-3 pt-md-5 text-align-left'>
                    <ul>
                        {faqData.map(item => (
                            <li key={item.id}>
                                <strong className='question'>{item.attributes.q}</strong>
                                <br />
                                {item.attributes.a.map(i => (
                                    <p className='answer' key={i}><span dangerouslySetInnerHTML={renderHTML(i)}/></p>
                                ))}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>   
        </div>
    )
}

export default Napady