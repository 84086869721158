// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-container {
    text-align: left;
    margin-left: 200px;
    margin-right: 200px;
}

.info-container h2 {
    text-align: center;
    font-size: 35px;
    font-weight: bold;
}
.info-container p span {
    color: rgba(167, 20, 63, 1);
    font-size: 18px;
    font-weight: 700;
}

.text-left {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/components/moreinfo/TheMoreInfo.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,2BAA2B;IAC3B,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".info-container {\n    text-align: left;\n    margin-left: 200px;\n    margin-right: 200px;\n}\n\n.info-container h2 {\n    text-align: center;\n    font-size: 35px;\n    font-weight: bold;\n}\n.info-container p span {\n    color: rgba(167, 20, 63, 1);\n    font-size: 18px;\n    font-weight: 700;\n}\n\n.text-left {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
