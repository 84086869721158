import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/imgs/logo-idolka.png';

import './TheNavigation.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

interface Props {

}

const TheNavigation: React.FC<Props> = () => {
    const scrollToNapad = () => {
        setTimeout(() => {
            const element = document.getElementById('napad');
            if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 50);
    };

    const scrollToAbout = () => {
        setTimeout(() => {
            const element = document.getElementById('about');
            if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 50);
    };

    return (
            <nav className="navbar navbar-expand-md navbar-light primary-color" id="header">
                <div className="container">
                    <a href="/"><img id='main-logo' src={logo} title="Idolka" alt='logo Idolka'/></a>
            
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navheader" aria-controls="navheader" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse justify-content-center" id="navheader">
                        <ul className="navbar-nav w-100 justify-content-center">
                            <li className='nav-item active'>
                                <NavLink className='linkos nav-link' to="/" onClick={scrollToAbout}>O APLIKACI</NavLink>
                            </li>
                            <li className='nav-item active'>
                                <NavLink className='linkos nav-link' to="/" onClick={scrollToNapad}>MÁM NÁPAD NA FUNKCI</NavLink>
                            </li>
                            <li className='nav-item active'>
                                <NavLink className='linkos nav-link' to="/faq">FAQ</NavLink>
                            </li>
                            <li className='nav-item active'>
                                <NavLink className='linkos nav-link' to="/novinky">NOVINKY</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>   
    )
}
export default TheNavigation