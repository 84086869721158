// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/footer-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.columnka {
    padding-top:245px
}

#contact-form-response {
    display: none;
}

.korid {
    max-width: 220px;
}

.idol, .oict {
    max-width: 110px;
}

footer {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-size: auto;
    background-position-x: 0%;
    background-position-x: center;
    color: #000000;
}

.margin-top-bot {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.margin-top {
    margin-top: 1.2rem;
    width: 150px;
    float: right;
}

.success {
    background-color: rgb(108, 184, 108);
    color: white;
    border-radius: 5px;
    transition: opacity 0.5s ease-in-out; /* Add a smooth fade-in/fade-out effect */
    opacity: 1;
}
.error {
    background-color: rgba(167, 20, 63, 1);
    color: white;
    border-radius: 5px;
}
.error p, .success p {
    margin-top: 9px;
}

.success.hide {
opacity: 0; /* Set opacity to 0 to hide the message */
}`, "",{"version":3,"sources":["webpack://./src/components/footer/TheFooterForm.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,6DAAoE;IACpE,qBAAqB;IACrB,yBAAyB;IACzB,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,oCAAoC;IACpC,YAAY;IACZ,kBAAkB;IAClB,oCAAoC,EAAE,yCAAyC;IAC/E,UAAU;AACd;AACA;IACI,sCAAsC;IACtC,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;;AAEA;AACA,UAAU,EAAE,yCAAyC;AACrD","sourcesContent":[".columnka {\n    padding-top:245px\n}\n\n#contact-form-response {\n    display: none;\n}\n\n.korid {\n    max-width: 220px;\n}\n\n.idol, .oict {\n    max-width: 110px;\n}\n\nfooter {\n    background: url('../../assets/imgs/footer-background.png') no-repeat;\n    background-size: auto;\n    background-position-x: 0%;\n    background-position-x: center;\n    color: #000000;\n}\n\n.margin-top-bot {\n    margin-top: 0.5rem;\n    margin-bottom: 0.5rem;\n}\n\n.margin-top {\n    margin-top: 1.2rem;\n    width: 150px;\n    float: right;\n}\n\n.success {\n    background-color: rgb(108, 184, 108);\n    color: white;\n    border-radius: 5px;\n    transition: opacity 0.5s ease-in-out; /* Add a smooth fade-in/fade-out effect */\n    opacity: 1;\n}\n.error {\n    background-color: rgba(167, 20, 63, 1);\n    color: white;\n    border-radius: 5px;\n}\n.error p, .success p {\n    margin-top: 9px;\n}\n\n.success.hide {\nopacity: 0; /* Set opacity to 0 to hide the message */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
