// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/imgs/mapa-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-up {
    max-width: 450px;
    margin-right: 120px;
}

.phone-down {
    max-width: 450px;
    margin-top: -300px;
}

@media screen and (min-width: 768px) {
    .screenshot-happyway-text {
        padding-left: 150px;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-left {
        text-align: left !important;
    }
}


#pay_go {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat;
    background-size: auto;
    background-position-x: 0%;
    background-position-x: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/pay/ThePay.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI;QACI,mBAAmB;IACvB;IACA;QACI,4BAA4B;IAChC;IACA;QACI,2BAA2B;IAC/B;AACJ;;;AAGA;IACI,6DAAkE;IAClE,qBAAqB;IACrB,yBAAyB;IACzB,6BAA6B;AACjC","sourcesContent":[".phone-up {\n    max-width: 450px;\n    margin-right: 120px;\n}\n\n.phone-down {\n    max-width: 450px;\n    margin-top: -300px;\n}\n\n@media screen and (min-width: 768px) {\n    .screenshot-happyway-text {\n        padding-left: 150px;\n    }\n    .text-md-right {\n        text-align: right !important;\n    }\n    .text-md-left {\n        text-align: left !important;\n    }\n}\n\n\n#pay_go {\n    background: url('../../assets/imgs/mapa-background.png') no-repeat;\n    background-size: auto;\n    background-position-x: 0%;\n    background-position-x: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
