import React from 'react'

import TheIntroduction from '../components/intro/TheIntroduction';
import TheTravel from '../components/travel/TheTravel';
import ThePay from '../components/pay/ThePay';
import TheMoreInfo from '../components/moreinfo/TheMoreInfo'
import '../App.css';


const Napady: React.FC = () => {
    return(
        <div className="App">
            <TheIntroduction/>
            <TheTravel/>
            <ThePay/>
            <TheMoreInfo/>
        </div>
    )
}

export default Napady