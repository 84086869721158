// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav#header {
    min-height: 100px;
    background-color: #ffffff;
    opacity: 0.8;
    border-bottom: 5px solid rgba(167, 20, 63, 1);
}

#main-logo {
  max-width: 251px;
}

nav#header ul li {
    padding: 0 30px 0 30px;
    text-transform: uppercase;
    cursor: pointer;
}

nav#header ul li .linkos {
    color: #000000;
    font-weight: bold;
    font-size: 19px;
    text-decoration: none;
}

nav#header ul li .linkos:hover {
    color: #000000;
    text-decoration: underline;
}
  `, "",{"version":3,"sources":["webpack://./src/components/navigation/TheNavigation.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,6CAA6C;AACjD;;AAEA;EACE,gBAAgB;AAClB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,0BAA0B;AAC9B","sourcesContent":["nav#header {\n    min-height: 100px;\n    background-color: #ffffff;\n    opacity: 0.8;\n    border-bottom: 5px solid rgba(167, 20, 63, 1);\n}\n\n#main-logo {\n  max-width: 251px;\n}\n\nnav#header ul li {\n    padding: 0 30px 0 30px;\n    text-transform: uppercase;\n    cursor: pointer;\n}\n\nnav#header ul li .linkos {\n    color: #000000;\n    font-weight: bold;\n    font-size: 19px;\n    text-decoration: none;\n}\n\nnav#header ul li .linkos:hover {\n    color: #000000;\n    text-decoration: underline;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
