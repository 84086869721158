// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#gdpr ol{
    padding-top: 12px;
    padding-bottom: 15px;
}

#gdpr ol > li {
    padding-bottom: 6px;
}

.gdpr-text ol li{
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/view/gdpr/TheGdpr.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["#gdpr ol{\n    padding-top: 12px;\n    padding-bottom: 15px;\n}\n\n#gdpr ol > li {\n    padding-bottom: 6px;\n}\n\n.gdpr-text ol li{\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
