import React from 'react';

import './TheGdpr.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const TheGdpr: React.FC = () => {
    return(
        <div id="gdpr" className="container">
            <div className="row">
                <div className="col-12 pt-3 pt-md-5 text-center">
                    <h2 className="montserrat-bold headline">
                        Informace o zpracování osobních údajů
                        <br/><span className="small">Mobilní aplikace IDOLKA, webový portál eshop.iidol.cz</span>
                    </h2>
                </div>

                <div className="col-12 pt-3 pt-md-5 gdpr-text">
                    <ol>
                        <li>Totožnost a kontaktní údaje správce
                            <p>Správcem Vašich osobních údajů je <span className="font-weight-bold">Liberecký kraj</span>, se sídlem U Jezu 642/2a, 461 80 Liberec 2, IČO: 70891508 (dále jen „správce“).</p>
                            <p className="font-weight-bold">Kontaktní údaje správce:</p>
                            <p>Adresa pro doručování: Liberecký kraj, U Jezu 642/2a, 461 80 Liberec 2</p>
                            <p>E-mail: <a href="mailto:podatelna@kraj-lbc.cz">podatelna@kraj-lbc.cz</a></p>
                            <p>Telefon: +420&nbsp;485&nbsp;226 111</p>
                            <p>ID datové schránky: c5kbvkw</p>
                            <p>Správce jmenoval Pověřence pro ochranu osobních údajů, e-mail: <a href="mailto:roman.sikola@kraj-lbc.cz">roman.sikola@kraj-lbc.cz</a>, telefon: +420&nbsp;485&nbsp;226&nbsp;356.</p>
                            <p className="font-weight-bold">Zpracovatel:</p>
                            <p>Zpracovatelem, který je pověřen správcem ke zpracování osobních údajů je společnost KORID LK, spol. s&nbsp;r.o., se sídlem U Jezu 642/2a, 461 80 Liberec 2, IČO: 70891508 (dále jen „zpracovatel“).</p>
                            <p>E-mail: <a href="mailto:info@korid.cz">info@korid.cz</a>, telefon: +420&nbsp;488&nbsp;588&nbsp;788, ID datové schránky: 3gpgib2</p>
                            <p>Zpracovatel jmenoval Pověřence pro ochranu osobních údajů, e-mail: <a href="mailto:dpo@korid.cz">dpo@korid.cz</a>, telefon: +420&nbsp;488&nbsp;588&nbsp;788.</p>
                        </li>
                        <li>Zákonné důvody zpracování osobních údajů
                            <p>Tyto informace o zpracování osobních údajů se vztahují na veškeré Vaše nákupy jízdních dokladů a další služby realizované prostřednictvím mobilní aplikace IDOLKA a webového portálu <a href="https://eshop.iidol.cz">eshop.iidol.cz</a>. Osobními údaji jsou všechny údaje, které se vztahují k Vaší osobě, jako subjektu údajů. Patří k nim Vaše jméno, příjmení, datum narození, , e-mailová adresa, telefonní číslo nebo Vaše uživatelské jednání při využívání služeb a dále pro registrovaného uživatele – e-mail a heslo, identifikátor instalace MA, identifikátor uživatele.</p>
                            <p>Správce zpracovává Vaše osobní údaje pouze na základě a v&nbsp;rozsahu dovoleném obecným nařízením Evropského parlamentu a Rady (EU) 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen: “GDPR”) a souvisejícími právními předpisy. Totéž platí i pro zpracovatele, kteří osobní údaje zpracovávají v&nbsp;souladu s&nbsp;pokyny správce.</p>
                            <p>Zákonnými důvody pro zpracování osobních údajů jsou plnění smlouvy nebo provedení opatření správcem před uzavřením takové smlouvy dle čl. 6 odst. 1 písm. b) GDPR, jakož i plnění právní povinnosti správce dle čl. 6 odst. 1 písm. c) GDPR.</p>
                            <p>Pokud je zpracování osobních údajů pro některé účely založeno dle čl. 6 odst. 1 písm. a) GDPR, na souhlasu subjektu údajů a tento souhlas byl udělen, lze udělený souhlas subjektem údajů kdykoliv odvolat. Odvoláním souhlasu však není dotčena zákonnost zpracování osobních údajů před jeho odvoláním. Subjekt údajů může souhlas odvolat jeho odvoláním doručeným správci. Komunikaci povedete písemně způsobem, který preferujete (e-mail, dopis).</p>
                        </li>
                        <li>Účely zpracování
                            <p>Vaše osobní údaje zpracováváme za účelem poskytování služeb prostřednictvím mobilní aplikace IDOLKA a webového portálu <a href="https://eshop.iidol.cz">eshop.iidol.cz</a>. Jedná se o účely prokázání totožnosti při nákupu jízdného, které je dle Tarifu nepřenosné, prokázání totožnosti cestujícího při odbavení v&nbsp;rámci systému IDOL, prokázání totožnosti pro případ reklamace a jiných nestandardních situací, dodržení platné legislativy, např. zákon o účetnictví.</p>
                            <p>Správce zpracovává pouze osobní údaje, které získal v souladu s právními předpisy, přičemž tyto osobní údaje shromažďuje a zpracovává pouze za stanoveným účelem, v nezbytném rozsahu a po dobu nutnou k naplnění tohoto účelu.</p>
                            <p>Správce dodržuje technická a organizační opatření, aby byla zajištěna úroveň zabezpečení, odpovídající všem možným rizikům s tím, že veškeré osoby, které přicházejí do styku s osobními údaji, mají povinnost dodržovat mlčenlivost o informacích získaných v souvislosti se zpracováním těchto údajů.</p>
                        </li>
                        <li>Příjemci osobních údajů
                            <p>Správce využívá partnery zodpovídající zejména za řádné fungování mobilní aplikace IDOLKA. Těmto partnerům může správce za účelem řádného plnění povinností vůči Vám, jako zákazníkovi Vaše osobní údaje zpřístupňovat. Všichni zpracovatelé jsou zavázání k&nbsp;dodržování platných předpisů o ochraně osobních údajů a budou mít přístup k Vašim osobním údajům pouze v takovém rozsahu a na takové časové období, které je potřebné pro poskytnutí příslušné služby.</p>
                            <p>S těmito partnery správce uzavřel smlouvu o zpracování osobních údajů. Aktuálně využíváme následující partnery:</p>
                            <ul>
                                <li>Poskytovatel IT služeb: Operátor ICT, a.s., Dělnická 213/12, 170 00 Praha 7, IČ: 02795281</li>
                                <li>Poskytovatel dat. úložiště: Operátor ICT, a.s., Dělnická 213/12, 170 00 Praha 7, IČ: 02795281</li>
                            </ul>
                            <p>Osobní údaje mohou být poskytnuty i orgánům veřejné moci v&nbsp;případě, že je to nezbytné pro ochranu před vznikem nedůvodné újmy na svobodě, životu, zdraví nebo vlastnictví jiného, vyžadují-li to právní předpisy.</p>
                            <p>K&nbsp;předávání osobních údajů do třetích zemí nebo mezinárodním organizacím nedochází.</p>
                        </li>
                        <li>Doba uložení osobních dat
                            <p>Vaše osobní údaje uchováváme tak dlouho, dokud je to nezbytné z důvodů potřebných pro dodržování našich zákonných povinností a účelům jejich zpracování.</p>
                            <p>Zpracování osobních údajů ukončíme po skončení Vašeho smluvního vztahu se správcem založeného registrací v&nbsp;mobilní aplikaci IDOLKA (souhlasem s&nbsp;Obchodními podmínkami užívání mobilní aplikace IDOLKA) tedy zrušením registrace, nejpozději však uplynutím 12 měsíců ode dne uplatnění jízdního dokladu zakoupeného v&nbsp;mobilní aplikaci IDOLKA před zrušením registrace. Archivaci údajů uvedených na daňových dokladech však ukončíme po uplynutí 10 let od jejich vystavení v&nbsp;souladu se zákonnou povinností.</p>
                            <p>To, jak dlouho si uchováváme osobní údaje, se může lišit v závislosti na našich právních závazcích, s ohledem na plnění smlouvy mezi správcem a Vámi. Pokud účel zpracování pomine dříve, ukončíme zpracování Vašich osobních údajů pro tento zaniklý účel i před koncem smluvního vztahu. Zpracování osobních údajů získaných na základě souhlasu ukončíme pro daný účel, jakmile odvoláte svůj souhlas. Vaše osobní údaje budeme bezpečně likvidovat v souladu s našimi zásadami uchovávání a vymazávání dat.</p>
                        </li>
                        <li>Používáme služby třetích stran
                            <p>Využíváme několik služeb třetích stran, které mohou sbírat informace použité k identifikaci uživatele. Odkaz na zásady ochrany osobních údajů třetích stran poskytovatele služeb, které aplikace používá (vývojové platformy pro mobilní aplikace):</p>
                            <ul>
                                <li>Firebase Analytics (Google)</li>
                                <li>Firebase Crashlytics (iOS, Android)</li>
                                <li>Fabric</li>
                            </ul>
                        </li>
                        <li>Vaše práva v souvislosti s ochranou osobních údajů
                            <p>Vaše osobní údaje zpracováváme transparentně, korektně a v souladu s legislativními požadavky. Při naplňování Vašich práv vycházíme z obecného nařízení GDPR.</p>
                            <p>Respektujeme Vaše práva na přístup k osobním údajům, které o Vás zpracováváme, právo na opravu (nepřesných či neúplných údajů), požadavek na výmaz („právo být zapomenut“), popřípadě omezení zpracování, a vznést námitku proti zpracování, jakož i práva na přenositelnost údajů.</p>
                            <p>Dalším právem je kdykoliv odvolat souhlas, jsou-li Vaše osobní údaje zpracovávány na základě Vašeho souhlasu a právo být informován o porušení zabezpečení osobních údajů v případech, kdy došlo k porušení zabezpečení a je pravděpodobné, že takový případ porušení zabezpečení osobních údajů bude mít za následek vysoké riziko pro práva a svobody fyzických osob.</p>
                            <p>Na Vaše žádosti, které se týkají uplatnění Vašich práv, budeme reagovat bez zbytečného odkladu, do jednoho měsíce od obdržení žádosti.</p>
                            <p>Vaše práva vztahující se k&nbsp;osobním údajům v&nbsp;rámci mobilní aplikace IDOLKA a webového portálu <a href="https://eshop.iidol.cz">eshop.iidol.cz</a>, včetně odvolání souhlasu, můžete uplatnit kdykoli níže uvedenými způsoby, které zajistí nejrychlejší vyřízení Vašeho požadavku:</p>
                            <ol>
                                <li>elektronicky na webu:  <a href="https://eshop.iidol.cz">eshop.iidol.cz</a></li>
                                <li>datová zpráva na ID: c5kbvkw</li>
                                <li>listovní psaní na adresu: Liberecký kraj, U Jezu 642/2a, 461 80 Liberec 2</li>
                                <li>e-mail na adresu: <a href="mailto:podatelna@kraj-lbc.cz">podatelna@kraj-lbc.cz</a> nebo <a href="mailto:roman.sikola@kraj-lbc.cz">roman.sikola@kraj-lbc.cz</a> nebo <a href="mailto:dpo@korid.cz">dpo@korid.cz</a></li>
                                <li>osobně na adrese: Liberecký kraj, U Jezu 642/2a, 461 80 Liberec 2</li>
                            </ol>
                            <p>Pro uplatnění Vašich práv ohledně osobních údajů a vyhovění Vašim požadavkům však musíme ze všeho nejdříve ověřit, že žadatelem jste opravdu Vy. Jedině tak zabráníme zneužití Vašich osobních údajů třetí osobou, nezvratné změně osobních údajů nebo dokonce jejich ztrátě. Dokumenty proto musí být opatřeny Vaším zaručeným elektronickým podpisem v&nbsp;případě, že je nahráváte na web nebo zasíláte e-mailem. Dokumenty můžete rovněž zaslat jako listovní psaní, které opatříte svým úředně ověřeným podpisem. Do předmětu komunikace či na obálku prosím, vždy uvádějte „GDPR“.</p>
                            <p>Pokud práva uplatníte osobně na podatelně v&nbsp;sídle správce na adrese Liberecký kraj, U Jezu 642/2a, 461 80 Liberec 2, bude pro ověření totožnosti požadováno pouze předložení Vašeho dokladu totožnosti vydaného orgánem státní správy.</p>
                            <p>Ačkoli Vám chceme umožnit co nejjednodušší způsob uplatnění Vašich práv, musíme eliminovat riziko neoprávněných zásahů do zpracování osobních údajů. Proto v&nbsp;případě, že budeme mít pochybnosti o tom, že požadavek uplatňujete Vy, dotážeme se Vás na další údaj(e).</p>
                            <p>Na případné námitky proti postupu správce můžete uplatnit právo podání stížnosti u dozorového úřadu, kterým je Úřad pro ochranu osobních údajů. Adresa: Pplk. Sochora 27, 170 00 Praha 7, web: <a href="http://www.uoou.cz" target="_blank" rel='noreferrer'>www.uoou.cz</a>.</p>
                        </li>
                        <li>Závěrečná ustanovení
                            <p>Tyto informace jsou platné a účinné ke dni 1.11.2022.</p>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    )
}
export default TheGdpr