import React, {useRef, useState, ChangeEvent, FormEvent} from 'react';
import './TheFooterForm.css';
import axios from 'axios';

//images
import korid from '../../assets/imgs/korid.png'
import idol from '../../assets/imgs/idol.png'
import oict from '../../assets/imgs/OICT_cerna.png'

interface Props {

}

const TheFooterForm: React.FC<Props> = () => {
    const formRef = useRef<HTMLFormElement>(null);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const faq = process.env.REACT_APP_ADDRESS_URL+"faq";
    const gdpr = process.env.REACT_APP_ADDRESS_URL+"gdpr";
    const vop = process.env.REACT_APP_ADDRESS_URL+"vop";
    
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null)
        setSuccess(null)

        const urlStrapi = process.env.REACT_APP_STRAPI_URL;

        const formData = new FormData(event.currentTarget);

        const jsonData = {
                data: {} as Record<string, string>
        };

        formData.forEach((value, key) => {
            jsonData.data[key] = value.toString();
        });
    
        try {
            if(jsonData.data.email && jsonData.data.name && jsonData.data.idea && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(jsonData.data.email)){
                const response = await axios.post(`${urlStrapi}requests`, jsonData);
                var inputN = document.getElementById("contact_form_name") as HTMLInputElement;
                var inputE = document.getElementById("contact_form_email") as HTMLInputElement;
                var inputI = document.getElementById("contact_form_message") as HTMLInputElement;
                inputN.value = '';
                inputE.value = '';
                inputI.value = '';
                setSuccess('Váš nápad byl odeslán.')
                setTimeout(() => {
                    setSuccess(null)
                  }, 5000);
            }
            else {
                setError('Špatně zadaný email nebo prázdná pole.')
            }
          } catch (error) {
            setError('Při odeslání nastala chyba, zkuste to prosím později.')
          }
    };

    return (
        <footer id="contact-us">
            <div className="container flex-wrap">
                <div className="row">
                    <div className="col-12 text-center pb-5 columnka">
                        <h2 id="napad" className="montserrat-bold headline">MÁTE NÁPAD NA DALŠÍ FUNKCE?
                            <br/>NAPIŠTE NÁM! </h2>

                        <div className="col-md-6 offset-md-3 pt-2 pt-md-5">
                            <div id="contact-form-response" className="alert alert-warning" role="alert"></div>

                            <form id="contact-form" onSubmit={handleSubmit} method="post" className="pb-5">
                                
                                <div className="form-group text-left">
                                    <label className="montserrat-semibold font-weight-bold margin-top-bot">Jméno a příjmení</label>
                                    <input type="text" id="contact_form_name" className="form-control" name="name" placeholder="Jan" maxLength={255}/>
                                </div>

                                <div className="form-group text-left">
                                    <label className="montserrat-semibold font-weight-bold margin-top-bot">E-mail</label>
                                    <input type="email" id="contact_form_email" className="form-control" name="email" placeholder="@" maxLength={255}/>
                                </div>

                                <div className="form-group text-left">
                                    <label className="montserrat-semibold font-weight-bold margin-top-bot">Nápad na zlepšení</label>
                                    <textarea id="contact_form_message" className="form-control" name="idea" placeholder="Text"></textarea>
                                </div>

                                <div className="form-group margin-top">
                                    <input id="contact_form_submit" type="submit" className="form-control float-right col-md-5 col-lg-3 montserrat-light color-cred border-radius-25" value="Odeslat"/>
                                </div>
                            </form>
                            {error != null &&
                                <div className='error mt-5 pt-1 pb-1'>
                                    <p>{error}</p>
                                </div>                            
                            }
                            {success != null &&
                                <div className='mt-5 pt-1 pb-1 success'>
                                    <p>{success}</p>
                                </div>                           
                            }

                            <div className="pt-5">
                                <img className="p-2 korid" src={korid} alt='korid'/>
                                <img className="p-2 idol" src={idol} alt='idol'/>
                                <img className="p-2 oict" src={oict} alt='oict'/>
                            </div>
                        </div>

                        <div className="col-12 pt-3 pt-md-5">
                            <a href={faq}>FAQ</a> | <a href={gdpr}>GDPR</a> | <a href={vop}>VOP</a>
                            | <a aria-label="Nastavení cookies" data-cc="c-settings" aria-haspopup="dialog">Nastavení cookies</a>
                            <br/>Copyright 2023 | Operátor ICT a.s.
                            <br/>Všechna práva vyhrazená. | Kontakt: info@korid.cz
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default TheFooterForm;