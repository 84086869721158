// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
    --cc-bg: #fff;
    --cc-text: #000000;
    --cc-btn-primary-bg: rgba(167, 20, 63, 1);
    --cc-btn-primary-hover-bg: rgb(193, 80, 113);
}

#cc_div, #s-cnt {
    font-family: 'Montserrat-UltraLight';
}

#cc_div #c-p-bn, #s-all-bn {
    background-color: rgba(167, 20, 63, 1) !important;
    color: white;
}


#cc_div #c-ttl {
    font-weight: 700;
}

#cc_div .cc-link {
    color: #000000;
    text-decoration: underline;
    border-bottom: unset;
    border-color: unset;
    font-weight: normal;
}

#cc_div .cc-link:hover {
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/cookie/CookieConsent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,yCAAyC;IACzC,4CAA4C;AAChD;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,iDAAiD;IACjD,YAAY;AAChB;;;AAGA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[":root{\n    --cc-bg: #fff;\n    --cc-text: #000000;\n    --cc-btn-primary-bg: rgba(167, 20, 63, 1);\n    --cc-btn-primary-hover-bg: rgb(193, 80, 113);\n}\n\n#cc_div, #s-cnt {\n    font-family: 'Montserrat-UltraLight';\n}\n\n#cc_div #c-p-bn, #s-all-bn {\n    background-color: rgba(167, 20, 63, 1) !important;\n    color: white;\n}\n\n\n#cc_div #c-ttl {\n    font-weight: 700;\n}\n\n#cc_div .cc-link {\n    color: #000000;\n    text-decoration: underline;\n    border-bottom: unset;\n    border-color: unset;\n    font-weight: normal;\n}\n\n#cc_div .cc-link:hover {\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
