// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#vop ol {
    counter-reset: item;
    list-style-type: none;
}

#vop ol > li {
    counter-increment: item;
}

#vop ol > li::before {
    content: counters(item, '.') ' ';
}

#vop ol {
    padding-top: 12px;
    padding-bottom: 15px;
}

#vop ol > li {
    padding-bottom: 6px;
}

.vop-text {
    text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/view/vop/TheVop.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["#vop ol {\n    counter-reset: item;\n    list-style-type: none;\n}\n\n#vop ol > li {\n    counter-increment: item;\n}\n\n#vop ol > li::before {\n    content: counters(item, '.') ' ';\n}\n\n#vop ol {\n    padding-top: 12px;\n    padding-bottom: 15px;\n}\n\n#vop ol > li {\n    padding-bottom: 6px;\n}\n\n.vop-text {\n    text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
