import { useEffect } from "react";

import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

export default function CookieConsent() {
    useEffect(() => {
        if (!document.getElementById('cc--main')) {
            var cc = window.initCookieConsent();
            cc.run({
                current_lang: 'cs',
                autoclear_cookies: true,                   // default: false
                // theme_css: './cookieconsent/cookieconsent.css',
                cookie_name: 'idolka-cookieconsent',             // default: 'cc_cookie'
                page_scripts: true,                         // default: false
            
                gui_options: {
                    consent_modal: {
                        layout: 'cloud',                      // box,cloud,bar
                        position: 'bottom center',           // bottom,middle,top + left,right,center
                        transition: 'slide'                 // zoom,slide
                    },
                    settings_modal: {
                        layout: 'box',                      // box,bar
                        // position: 'left',                // right,left (available only if bar layout selected)
                        transition: 'slide'                 // zoom,slide
                    } 
                },

                onAccept: function (cookie) {
                    //console.log('onAccept fired ...');
                },
            
                onChange: function (cookie, changed_preferences) {
                    // console.log('onChange fired ...');
            
                    // If analytics category's status was changed
                    if (changed_preferences.indexOf('analytics') > -1) {
                        var dataLayer = window.dataLayer || [];
                        function gtag() { dataLayer.push(arguments); }
                        // If analytics category is disabled
                        if (!cc.allowedCategory('analytics')) {
                            // Disable ga
                            // console.log('disabling gtag')
                            gtag('consent', 'default', {
                                'ad_storage': 'denied',
                                'analytics_storage': 'denied'
                            });
                        } else {
                            // Enable ga
                            gtag('consent', 'update', {
                                'analytics_storage': 'granted'
                            });
                        }
                    }
                },
            
                languages: {
                    cs: {
                        consent_modal: {
                            title: "Používáme cookies",
                            description: "Díky využití souborů cookies lépe chápeme, co vás zajímá a můžeme vám připravit ten nejlepší uživatelský zážitek. Dejte nám vědět, zda souhlasíte s jejich použitím, nebo si je <button type=\"button\" data-cc=\"c-settings\" class=\"cc-link\">přizpůsobte podle svých představ</button>.",
                            primary_btn: {
                                text: "Povolit vše",
                                role: "accept_all", // 'accept_selected' or 'accept_all'
                            },
                            secondary_btn: {
                                text: "Povolit nezbytné",
                                role: "accept_necessary", // 'settings' or 'accept_necessary'
                            },
                        },
                        settings_modal: {
                            title: "Přizpůsobit nastavení cookies",
                            save_settings_btn: "Uložit nastavení",
                            accept_all_btn: "Povolit vše",
                            reject_all_btn: "Povolit nezbytné",
                            close_btn_label: "Zavřít",
                            blocks: [
                                {
                                    title: "Nezbytně nutné cookies",
                                    description: "Tyto cookies pomáhají, aby webová stránka byla použitelná a fungovala správně. Ve výchozím nastavení jsou povoleny a nelze je zakázat.",
                                    toggle: {
                                        value: "necessary",
                                        enabled: true,
                                        readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                                    },
                                },
                                {
                                    title: "Statistika",
                                    description: "Díky těmto cookies víme, kam u nás chodíte nejraději a máme statistiky o celkové návštěvnosti našich stránek.",
                                    toggle: {
                                        value: "analytics", // there are no default categories => you specify them
                                        enabled: false,
                                        readonly: false,
                                    },
                                },
                            ],
                        },
                    },
                }                  
            });
        }

    }, []);

    return null;
}