// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#container ul li{
    text-align: left;
}

#container h1 {
    font-size: 35px;
    font-weight: bold;
    font-family: 'Montserrat-Bold', sans-serif;
    margin-top: 37px;
    margin-bottom: 37px;
}

#container ul li strong{
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0;
    font-weight: normal;
}
#container ul li p{
    font-size: 16px;
    font-weight: lighter;
    line-height: 1.5;
    margin-top: 0;
}
#container ul li a {
    color: #000000;
    text-decoration: underline;
}
#container {
    max-width: 1140px;
    margin: 0 auto;
    font-family: 'Montserrat-UltraLight', sans-serif;
}

.text-align-left {
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/view/faq/Faq.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,0CAA0C;IAC1C,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,cAAc;IACd,0BAA0B;AAC9B;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,gDAAgD;AACpD;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["#container ul li{\n    text-align: left;\n}\n\n#container h1 {\n    font-size: 35px;\n    font-weight: bold;\n    font-family: 'Montserrat-Bold', sans-serif;\n    margin-top: 37px;\n    margin-bottom: 37px;\n}\n\n#container ul li strong{\n    font-size: 1rem;\n    line-height: 1.5;\n    margin-bottom: 0;\n    font-weight: normal;\n}\n#container ul li p{\n    font-size: 16px;\n    font-weight: lighter;\n    line-height: 1.5;\n    margin-top: 0;\n}\n#container ul li a {\n    color: #000000;\n    text-decoration: underline;\n}\n#container {\n    max-width: 1140px;\n    margin: 0 auto;\n    font-family: 'Montserrat-UltraLight', sans-serif;\n}\n\n.text-align-left {\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
